import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { Layout, ShareBlock } from "../components";
import AuthorBlock from "../components/Author/AuthorBlock";
import {
  PostMainImage,
  MainContent,
  PostDescription,
  PostTitle,
  PostWrapper,
  ImageDescription,
} from "../components/Post";
import Seo from "../components/seo";
import { Container } from "../components/Post/Container";

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        description
        title
        author_name
        mainImageDesc
        author_avatar {
          childImageSharp {
            gatsbyImageData(width: 36, height: 36, layout: FIXED)
          }
        }
        background_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        publication_date(formatString: "MMM D YYYY")
      }
      timeToRead
      html
    }
    file(relativePath: { eq: "path/to/background_image.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

const BlogPost = ({ data: { markdownRemark, file }, location }) => {
  const url = location?.href ? location.href : "";
  const { frontmatter, html, timeToRead } = markdownRemark;
  const author = {
    name: frontmatter.author_name,
    avatar: frontmatter.author_avatar,
    date: frontmatter.publication_date,
    timeToRead,
  };
  const image = getImage(frontmatter.background_image || file?.childImageSharp);

  return (
      <Layout>
        <PostWrapper>
          <PostMainImage alt={frontmatter.title} image={image} />
          {!!frontmatter.mainImageDesc && <ImageDescription>{frontmatter.mainImageDesc}</ImageDescription>}
          <Container>
            <PostTitle>{frontmatter.title}</PostTitle>
            <PostDescription>{frontmatter.description}</PostDescription>
            <AuthorBlock author={author} />
            <MainContent dangerouslySetInnerHTML={{ __html: html }} />
            <ShareBlock url={url} title={frontmatter.title}/>
          </Container>
        </PostWrapper>
      </Layout>
  );
};
export default BlogPost;

export const Head = ({ data }) => {
  const title = data?.markdownRemark?.frontmatter?.title;
  const image = getImage(data?.markdownRemark?.frontmatter?.background_image || data?.file?.childImageSharp);
  return <Seo title={title} image={image} />;
};
